export const UnderConstruction = (): JSX.Element => {
    return (
        <div
            style={{
                width: '100%',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <p>Under Construction.</p>
        </div>
    );
};
